import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { User } from '../types/types';
import { BehaviorSubject, Subject } from 'rxjs';
const defaultUser = {
    uri: '',
    email: '',
    last_name: '',
    first_name: '',
};
@Injectable({
    providedIn: 'root',
})
export class UserService {
    private current = new BehaviorSubject<User>(defaultUser);
    public current$ = this.current.asObservable();
    public userLoaded = new Subject<boolean>();
    constructor() {}
    public initUser() {
        if (sessionStorage.getItem('user')) {
            this.current.next(JSON.parse(sessionStorage.getItem('user')!));
            this.userLoaded.next(true);
            return;
        }
        if (localStorage.getItem('user')) {
            const expireDate = localStorage.getItem('sessionExpireDate');
            if (expireDate && DateTime.fromISO(expireDate) > DateTime.now()) {
                this.current.next(JSON.parse(localStorage.getItem('user')!));
                this.userLoaded.next(true);
                return;
            }
        }
        localStorage.removeItem('user');
        localStorage.removeItem('sessionExpireDate');
    }
    public storeUser(newUser: User) {
        this.current.next(newUser);
        sessionStorage.setItem('user', JSON.stringify(newUser));
        localStorage.setItem('user', JSON.stringify(newUser));
        localStorage.setItem('sessionExpireDate', DateTime.now().plus({ day: 7 }).toISO() || '');
    }
    public saveAccessToken(accessToken: string) {
        sessionStorage.setItem('accessToken', accessToken);
        localStorage.setItem('accessToken', accessToken);
    }
    public getAccessToken() {
        return sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken') || undefined;
    }
    public cleanUserData() {
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('sessionExpireDate');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('accessToken');
        this.current.next(defaultUser);
    }
    public getCurrent() {
        return this.current.getValue();
    }
    public isLoggedIn() {
        return !!this.getCurrent().uri;
    }
}
